#root_register {
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;

    .register_wrapper {
        margin: 5%;
        background-color: #222;
        padding: 2.5%;
        border-radius: 5px;
    
        border: 1px solid #000;
        box-shadow: 0 3px 3px rgba(94, 94, 94, 0.14);
    }
    
    .register_link {
        text-decoration: none;
        color: #c85400;
    }
} 