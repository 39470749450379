@import url(//fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic);
@import url(//fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,800,700,900);

body {
  margin: 0; 
  background-image: url('./assets/img/patterns/papyrus-dark.png');
  background-repeat: repeat;
  background-attachment: fixed;
  height: 100%;

  &, p, i, small { 
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', serif; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
  }
  
}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body,
html,
#root,
#home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.spinner {
    animation: spin infinite 5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
}

.hover {
    &:hover {
        cursor: pointer;
    }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}