 .root_desktop {
     #home_wrapper {
         margin-top: 25px;

         .MuiListItem-Root {

             /* Center tables for demo */
             table {
                 margin: 0 auto;
             }

             /* Default Table Style */
             table {
                 color: #333;
                 background: white;
                 border: 1px solid grey;
                 font-size: 12pt;
                 border-collapse: collapse;
             }

             table thead th,
             table tfoot th {
                 color: #777;
                 background: rgba(0, 0, 0, .1);
             }

             table caption {
                 padding: .5em;
             }

             table th,
             table td {
                 padding: .5em;
                 border: 1px solid lightgrey;
             }

             /* Zebra Table Style */
             [data-table-theme*=zebra] tbody tr:nth-of-type(odd) {
                 background: rgba(0, 0, 0, .05);
             }

             [data-table-theme*=zebra][data-table-theme*=dark] tbody tr:nth-of-type(odd) {
                 background: rgba(255, 255, 255, .05);
             }

             /* Dark Style */
             [data-table-theme*=dark] {
                 color: #ddd;
                 background: #333;
                 font-size: 12pt;
                 border-collapse: collapse;
             }

             [data-table-theme*=dark] thead th,
             [data-table-theme*=dark] tfoot th {
                 color: #aaa;
                 background: rgba(0255, 255, 255, .15);
             }

             [data-table-theme*=dark] caption {
                 padding: .5em;
             }

             [data-table-theme*=dark] th,
             [data-table-theme*=dark] td {
                 padding: .5em;
                 border: 1px solid grey;
             }
         }
     }
 }

 .qrCodeBox {
     transition: all ease-in-out 0.25s;
     border: 1px solid black;
     margin-bottom: 7.5px;
     box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, .25);
     background-color: white;
 }

 .qrCodeBoxIntro {
     background-color: rgb(124, 190, 223);
     margin-bottom: 10px;

     p,
     h2 {
         margin: 0;
         padding: 0;
     }
 }


 .qrCodeBox:hover {
     box-shadow: 1px 1px 2px 0px rgba(255, 255, 255, .5);
     border-radius: 10px;
     margin-top: 10px;
     margin-bottom: 10px;
     border: 4px solid rgb(124, 190, 223);
 }

 .MuiCardContent-root {
     padding: 0;
     margin: 2.5px;
 }