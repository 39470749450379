#root_login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 

    .login_wrapper {
        margin: 5%;
        background-color: #FFF;
        padding: 2.5%;
        border-radius: 5px;
    
        border: 1px solid #000;
        box-shadow: 0 3px 3px rgba(94, 94, 94, 0.14);
    }
    
    .login_link {
        text-decoration: none;
        color: #c85400;
    }
     
}