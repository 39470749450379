.qr-code-input-container {
    padding: 1.5%;
    background-color: white;
    color: black;
}

.qr-code-input-item {
    margin: 0% 0% 5% 0%;
    padding: 1%;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, .25);
    transition: 0.25s all ease-in-out;
}