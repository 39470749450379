#desktop_top_nav {
    height: 50px;
} 

#desktop_top_nav + div {
    margin-top: 50px;
}

.nav-link:hover {
    letter-spacing: 2px;
    transition: 0.25s all ease-in-out
}